<template>
  <v-container id="store" fluid tag="section">
    <section class="ui two column centered grid">
      <div class="column">
        <form class="ui segment large form">
          <div class="field">
            <div class="ui right icon input large">
              <input
                type="text"
                placeholder="Enter your address"
                v-model="address"
                ref="autocomplete"
              />
              <i class="dot circle link icon" @click="locatorButtonPressed"></i>
            </div>
          </div>
        </form>
      </div>
    </section>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      address: "",
    };
  },
  methods: {
    locatorButtonPressed() {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          console.log(position.coords.latitude);
          console.log(position.coords.longitude);
        },
        (error) => {
          console.log(error.message);
        }
      );
    },
  },
  mounted() {
    new google.maps.places.Autocomplete(this.$refs["autocomplete"]);
  },
};
</script>
